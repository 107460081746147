import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 41 39"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M20.5,0C9.2,0,0,7.5,0,16.6C0,24.8,7.3,31.7,17.1,33c0.7,0.1,1.6,0.4,1.8,1c0.2,0.5,0.1,1.3,0.1,1.9\n\tc0,0-0.2,1.4-0.3,1.8c-0.1,0.5-0.4,2,1.8,1.1s11.8-6.9,16.1-11.9c3-3.3,4.4-6.6,4.4-10.2C40.9,7.5,31.7,0,20.5,0z M13.2,21.5\n\tc0,0.2-0.2,0.4-0.4,0.4H7.1c-0.1,0-0.2,0-0.3-0.1l0,0l0,0c-0.1-0.1-0.1-0.2-0.1-0.3v-8.9c0-0.2,0.2-0.4,0.4-0.4h1.4\n\tc0.2,0,0.4,0.2,0.4,0.4v7.1h3.9c0.2,0,0.4,0.2,0.4,0.4V21.5z M16.7,21.5c0,0.2-0.2,0.4-0.4,0.4h-1.4c-0.2,0-0.4-0.2-0.4-0.4v-8.9\n\tc0-0.2,0.2-0.4,0.4-0.4h1.4c0.2,0,0.4,0.2,0.4,0.4V21.5z M26.6,21.5c0,0.2-0.2,0.4-0.4,0.4h-1.4c0,0-0.1,0-0.1,0h0c0,0,0,0,0,0\n\tc0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1l-4.1-5.5v5.3c0,0.2-0.2,0.4-0.4,0.4\n\th-1.4c-0.2,0-0.4-0.2-0.4-0.4v-8.9c0-0.2,0.2-0.4,0.4-0.4H20c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0\n\tc0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0\n\tc0,0,0,0,0,0l4.1,5.5v-5.3c0-0.2,0.2-0.4,0.4-0.4h1.4c0.2,0,0.4,0.2,0.4,0.4V21.5z M34.5,14c0,0.2-0.2,0.4-0.4,0.4v0h-3.9v1.5h3.9\n\tc0.2,0,0.4,0.2,0.4,0.4v1.4c0,0.2-0.2,0.4-0.4,0.4h-3.9v1.5h3.9c0.2,0,0.4,0.2,0.4,0.4v1.4c0,0.2-0.2,0.4-0.4,0.4h-5.7\n\tc-0.1,0-0.2,0-0.3-0.1l0,0C28,21.7,28,21.6,28,21.5v-8.9c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0,0l0,0c0.1-0.1,0.2-0.1,0.3-0.1h5.7\n\tc0.2,0,0.4,0.2,0.4,0.4V14z" }, null, -1)
  ])))
}
export default { render: render }